.terminal-container {
  background-image: url(https://www.teahub.io/photos/full/162-1626036_thumbnail-image-ubuntu-18-10-cosmic-cuttlefish.jpg);
  background-size: cover;
}

.ul-remove-bullets {
  list-style-type: none;
}

.terminal-wrap {
  margin: 10px auto 0 auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 85vh;
  width: 80vw;
  margin-top: 50px;
}

.terminal-close-button {
  background-color: #bf4b24;
  width: 18px;
  height: 18px;
  border-radius: 15px;
  padding: 1px 1.5px;
  color: #fff;
  cursor: pointer;
}

.terminal-close-button:hover {
  background-color: #a05b44;
}

.terminal-top-bar {
  /* text-align: center; */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: #525252;
  padding: 5px 10px;
  margin: 0;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
  font-size: 0.85em;
  border: 1px solid #cccccc;
  border-bottom: none;

  -webkit-border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-topright: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  background: #f7f7f7; /* Old browsers */
  background: -moz-linear-gradient(top, #f7f7f7 0%, #b8b8b8 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #f7f7f7),
    color-stop(100%, #b8b8b8)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    #f7f7f7 0%,
    #b8b8b8 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    #f7f7f7 0%,
    #b8b8b8 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #f7f7f7 0%, #b8b8b8 100%); /* IE10+ */
  background: linear-gradient(to bottom, #f7f7f7 0%, #b8b8b8 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#B8B8B8',GradientType=0 ); /* IE6-9 */
}

.terminal-body {
  margin: 0;
  padding: 5px;
  list-style: none;
  background: #300924;
  color: #fff;
  font: 0.8em 'Andale Mono', Consolas, 'Courier New';
  line-height: 1.6em;

  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  height: calc(100% - 27px);
  overflow: auto;
}

.terminal-body li.line:before {
  content: '$';
  position: absolute;
  left: 0;
  top: 0;
}

.terminal-body li.bullet:before {
  content: '> ';
  left: 15;
  top: 0;
}

.terminal-body li.line.talk-activated:before {
  content: '$ talk >';
  left: 15;
  top: 0;
}

.terminal-body li.no-bullet:before {
  left: 15;
  top: 0;
}

.terminal-body li.line {
  word-wrap: break-word;
  position: relative;
  padding: 0 0 0 15px;
}

.terminal-body li.bullet {
  word-wrap: break-word;
  position: relative;
  padding: 0 0 0 30px;
}

.terminal-body li.line.talk-activated {
  word-wrap: break-word;
  position: relative;
  padding: 0 0 0 68px;
}

.terminal-body li.no-bullet {
  word-wrap: break-word;
  position: relative;
  padding: 0 0 0 30px;
}

.terminal-input {
  outline: none;
  border: none;
  background: #300924;
  width: 100%;
  color: #fff;
}

.terminal-footer {
  padding-top: 20px;
}

.terminal-footer a.btn {
  background-color: #5f5f5f;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.terminal-footer a.btn:hover {
  background: #444343;
}

.close-terminal-btn {
  float: right;
}

.remove-link-styles {
  text-decoration: none;
  color: #fff;
}

.remove-link-styles:hover {
  color: blue;
}

.icon-link {
  text-decoration: none;
  color: #fff;
}

.icon-link:hover {
  color: white;
}

.line-2 {
  display: none;
}

.terminal-btn {
  border: 1px dashed;
  padding: 1px 7px;
}
